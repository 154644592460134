<template>
    <div class="main-box">
        <a-form :model="formGeren" :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="ec-box">
                <div class="title">
                    <div :class="{ 'tab-active': tabIndex == 1, 'tab': tabIndex != 1 }" @click="onTab(1)">产品发布</div>
                    <div :class="{ 'tab-active': tabIndex == 2, 'tab': tabIndex != 2 }" @click="onTab(2)">产品列表</div>
                </div>
                <div class="content" v-if="tabIndex == 1">
                    <div class="con-item">
                        <a-form-item label="服务项" name="fuwuxiang">
                            <a-select v-model:value="formGeren.fuwuxiang" placeholder="请选择服务项">
                                <a-select-option v-for="(sItem, si) in serviceList" :key="si" :value="sItem.BIANMA">{{sItem.NAME}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="产品名称" name="chanpinmingcheng">
                            <a-input v-model:value="formGeren.chanpinmingcheng" placeholder="请输入产品名称" />
                        </a-form-item>
                    </div>
                    <div class="con-item-upload">
                        <a-form-item label="产品LOGO" name="logo">
                             <a-upload
                                name="file"
                                :action="$uploadApi"
                                :headers="headers"
                                @change="onUpload"
                                :before-upload="beforeUpload"
                                :default-file-list="fileList"
                            >
                                <a-button >
                                    <upload-outlined></upload-outlined>
                                    请选择文件
                                </a-button>
                            </a-upload>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="产品分类" name="chanpinfenlei">
                            <a-select v-model:value="formGeren.chanpinfenlei" placeholder="请选择产品分类">
                                <a-select-option value="机构产品">机构产品</a-select-option>
                                <a-select-option value="自由产品">自有产品</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="利率区间" name="lilvqujian">
                            <a-input v-model:value="formGeren.lilvqujian1" placeholder="请输入利率区间"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="额度范围" name="edufanwei">
                            <a-select v-model:value="formGeren.edufanwei" placeholder="请选择额度范围">
                                <a-select-option v-for="(qItem, qi) in quotaList" :key="qi" :value="qItem.BIANMA">{{qItem.NAME}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="担保方式" name="danbaofangshi">
                            <a-select v-model:value="formGeren.danbaofangshi" placeholder="请选择担保方式">
                                <a-select-option v-for="(mItem, mi) in methodList" :key="mi" :value="mItem.NAME">{{mItem.NAME}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="期限范围" name="qixianfanwei">
                            <a-select v-model:value="formGeren.qixianfanwei" placeholder="请选择期限范围">
                                <a-select-option v-for="(dItem, di) in dateList" :key="di" :value="dItem.BIANMA">{{dItem.NAME}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="发布时间" name="fabushijian">
                            <a-date-picker v-model:value="formGeren.fabushijian" :format="dateFormat" @change="onPickTime" style="width: 100%" placeholder="请选择发布时间" />
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="产品有效期" name="chanpinyouxiaoqi">
                            <a-range-picker v-model:value="formGeren.chanpinyouxiaoqi" @change="onPickDate" style="width: 100%"/>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="适用区域">
                            <a-input v-model:value="formGeren.shiyongquyu" placeholder="请输入适用区域"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="适用客户">
                            <a-input v-model:value="formGeren.shiyongkehu" placeholder="请输入适用客户"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="产品特点" name="chanpintedian">
                            <a-textarea v-model:value="formGeren.chanpintedian" placeholder="限300字" style="height: 100px;" />
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="产品简介" name="chanpinjianjie">
                            <a-textarea v-model:value="formGeren.chanpinjianjie" placeholder="限300字" style="height: 100px;" />
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="申请条件" name="shenqingtiaojian">
                            <a-textarea v-model:value="formGeren.shenqingtiaojian" placeholder="限300字" style="height: 100px;" />
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="提交材料" name="tijiaocailiao">
                            <a-textarea v-model:value="formGeren.tijiaocailiao" placeholder="限300字" style="height: 100px;" />
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="分支机构" name="fenzhijigou">
                            <a-textarea v-model:value="formGeren.fenzhijigou" disabled style="height: 100px;" />
                            <a-button @click="bankShow = true">选择业务承办机构</a-button>
                        </a-form-item>
                    </div>
                    <div class="con-item" style="visibility: hidden;">
                        <a-form-item>
                            <a-textarea/>
                        </a-form-item>
                    </div>
                    <div class="contents">
                        <a-button @click="onSubmit">确认提交</a-button>
                    </div>
                </div>
                <div class="content-box" v-else>
                    <div style="display: flex;margin-top: 20px;">
                        <div style="width: 30%;">
                            <a-form-item label="服务种类" name="fuwuzhonglei">
                                <a-select v-model:value="fuwuzhonglei" placeholder="请选择服务项">
                                    <a-select-option v-for="(ssItem, sii) in serviceList" :key="sii" :value="ssItem.BIANMA">{{ssItem.NAME}}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </div>
                        <div style="width: 30%;">
                            <a-form-item label="产品名称" name="cpName">
                                <a-input v-model:value="cpName"  placeholder="请输入产品名称" />
                            </a-form-item>
                        </div>
                        <div style="width: 25%;">
                            <a-button @click="getProductList">确定筛选</a-button>
                        </div>
                    </div>
                    <a-table :columns="columns" :data-source="pList" :loading="loading" :pagination="pagination" @change="onPage">
                        <template #product="{ text }">
                            <a>{{text}}</a>
                        </template>
                        <template #operation="{record}">
                            <a-button @click="onEdit(record)">编辑</a-button>
                            <a-popconfirm
                                title="确定要删除此条记录吗?"
                                ok-text="是"
                                cancel-text="否"
                                @confirm="onDelete(record)"
                                @cancel="cancel"
                            >
                                <a-button type="primary">删除</a-button>
                            </a-popconfirm>
                        </template>
                    </a-table>
                </div>
            </div>
        </a-form>
        <a-modal
            :visible="bankShow"
            title="业务承办机构"
            @cancel="bankShow = false;searchBankVal = ''"
            @ok="onConfirmBankList"
            ok-text="确定"
            cancel-text="取消"
        >
            <a-input-search v-model:value="searchBankVal" enter-button placeholder="请输入机构名称" @search="getBankList" style="margin-bottom: 20px;"/>
            <div style="height: 200px;overflow-y: scroll;">
                <p v-for="(bItem, bi) in bankLists" :key="bi" @change="onPickBank(bItem)">
                    <a-checkbox :checked="bItem.selected">
                        {{bItem.NAME}}
                    </a-checkbox>
                </p>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import { UploadOutlined } from '@ant-design/icons-vue'
import {AddProducts} from '@/api/other/other'
import { mapGetters } from "vuex"
import moment from 'moment'
import 'moment/locale/zh-cn'
 import axios from '@/api/axios'


export default defineComponent({
    components: {
        UploadOutlined
    },
    computed: {
        ...mapGetters(['userName', 'orgName', 'orgId', 'productDetail'])
    },
    setup() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
                key: 'key',
                align:'center',
                width:60
            },
            {
                title: '服务项',
                dataIndex: 'fwx',
                key: 'fwx',
                align:'center'
            },
            {
                title: '产品名称',
                dataIndex: 'product',
                key: 'product',
                align:'center',
                slots: {
                    customRender: 'product'
                }
            },
            {
                title: '期限范围',
                dataIndex: 'timeLimit',
                key: 'timeLimit',
                align:'center'
            },
            {
                title: '担保方式',
                dataIndex: 'assureMeans',
                key: 'assureMeans',
                align:'center'
            },
            {
                title: '额度范围',
                dataIndex: 'limitRange',
                key: 'limitRange',
                align:'center'
            },
            {
                title: '发布时间',
                dataIndex: 'time',
                key: 'time',
                width: 120,
                align:'center'
            },
            {
                title: '操作',
                dataIndex: 'operation',
                align:'center',
                slots: {
                    customRender: 'operation'
                }
            }
        ]
        const fileList = ref([])
        const formGeren = reactive({
            fuwuxiang: undefined,
            chanpinmingcheng: '',
            logo: '',
            lilvqujian1: '',
            edufanwei: undefined,
            chanpintedian: '',
            chanpinjianjie: '',
            danbaofangshi: undefined,
            qixianfanwei: undefined,
            fabushijian: '',
            shenqingtiaojian: '',
            tijiaocailiao: '',
            chanpinfenlei: undefined,
            chanpinyouxiaoqi: [],
            shiyongquyu: '',
            shiyongkehu: '',
            fenzhijigou: ''
        })
        return {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            labelCol: {
                span: 6,
            },
            wrapperCol: {
                span: 16,
            },
            formGeren,
            fileList,
            fileAddr: '',
            fileName: '',
            columns
        }
    },
    data () {
        return {
            tabIndex: 1,
            moment,
            fuwuzhonglei: undefined,
            cpName: '',
            cpStatus: undefined,
            dateList: [],
            methodList: [],
            quotaList: [],
            pList: [],
            serviceList: [],
            dateFormat: 'YYYY-MM-DD',
            isEdit: false,
            bankLists: [],
            bankShow: false,
            searchBankVal: '',
            loading: false,
            pagination: {},
            page: 1,
            flag: false
        }
    },
    methods: {
        onConfirmBankList () {
            let str = ''
            for (let i in this.bankLists) {
                if (this.bankLists[i].selected) {
                    str += this.bankLists[i].NAME + ','
                }
            }
            str = str.substring(0, str.length - 1)
            this.formGeren.fenzhijigou = str
            this.bankShow = false
            this.searchBankVal = ''
            this.flag = true
        },
        onPickBank (item) {
            item.selected = !item.selected
        },
        // 编辑产品
        onEdit (item) {
            var that = this
            this.$store.dispatch('getProductDetail', { FINANCIALPRODUCTS_ID: item.pid, tm: new Date().getTime() })
            this.onTab(1)
            this.proId = item.pid
            setTimeout(() => {
                this.isEdit = true
                this.formGeren.fuwuxiang = this.productDetail.SERVICE_COLUMN
                this.formGeren.chanpinmingcheng = this.productDetail.PRODUCT_NAME
                this.formGeren.lilvqujian1 = this.productDetail.INTEREST_RATE_START
                this.formGeren.edufanwei = this.productDetail.MONEY_START.toString()
                this.formGeren.chanpintedian = this.productDetail.PRODUCTS_CHAR
                this.formGeren.chanpinjianjie = this.productDetail.PRODUCTS_REMARK
                this.formGeren.danbaofangshi = this.productDetail.GUARANTEE_METHOD
                this.formGeren.qixianfanwei = this.productDetail.BETWEEN_MONTHS_S.toString()
                this.formGeren.shenqingtiaojian = this.productDetail.APPLY_CONDISTION
                this.formGeren.tijiaocailiao = this.productDetail.MAKINGS
                this.formGeren.chanpinfenlei = this.productDetail.PRODUCT_TYPE
                this.formGeren.chanpinyouxiaoqi = [this.productDetail.DATE_START, this.productDetail.DATE_END]
                this.formGeren.shiyongquyu = this.productDetail.APPLICABLE_REGION
                this.formGeren.shiyongkehu = this.productDetail.APPLICABLE_AGENT
                this.formGeren.fabushijian = this.productDetail.RELEAS_TIME
                this.formGeren.HANDINST_BANKNAME = this.productDetail.HANDINST_BANKNAME
                this.formGeren.HANDINST_BANKID = this.productDetail.HANDINST_BANKID
                this.fileName = this.productDetail.LOGO_NAME
                this.formGeren.LOGO_ADDR = this.productDetail.LOGO_ADDR
                this.formGeren.fenzhijigou = this.productDetail.HANDINST_BANKNAME
                    that.getBankList()
            }, 1500)
        },
        // 删除产品
        onDelete (item) {
            this.$store.dispatch('deleteProducts', { FINANCIALPRODUCTS_ID: item.pid, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getProductList()
                } else {
                    this.$message.error('系统异常，删除失败，请稍后重试！')
                }
            })
        },
        onTab (tType) {
            this.tabIndex = tType
            this.init()
            if (tType == 2) {
                this.getProductList()
            }
        },
        // 获取产品列表
        getProductList () {
            this.loading = true
            this.pList = []
            this.$store.dispatch('getProductList', {
                showCount: 10,
                currentPage: this.page,
                BANK_ID: this.orgId,
                SERVICE_COLUMN: this.fuwuzhonglei,
                PRODUCT_NAME: this.cpName
            }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        this.pagination.total = res.page.totalResult
                        for (let i in datas) {
                            this.pList.push({
                                key: parseInt(i) + 1,
                                pid: datas[i].FINANCIALPRODUCTS_ID,
                                institutionName: datas[i].BANK_ID,
                                fwx: datas[i].SERVICE_COLUMN,
                                product: datas[i].PRODUCT_NAME,
                                timeLimit: datas[i].BETWEEN_MONTHS_S + '个月以下',
                                assureMeans: datas[i].GUARANTEE_METHOD,
                                limitRange: datas[i].MONEY_START + '万以下',
                                time: datas[i].RELEAS_TIME.split(' ')[0],
                                BANK_ID: datas[i].BANK_ID,
                                PRODUCT_TYPE: datas[i].PRODUCT_TYPE,
                                MONEY_START: datas[i].MONEY_START,
                                BETWEEN_MONTHS_S: datas[i].BETWEEN_MONTHS_S,
                                DATE_START: datas[i].DATE_START,
                                DATE_END: datas[i].DATE_END,
                                INTEREST_RATE_START: datas[i].INTEREST_RATE_START,
                                RELEAS_TIME: datas[i].RELEAS_TIME,
                                APPLICABLE_AGENT: datas[i].APPLICABLE_AGENT,
                                PRODUCTS_CHAR: datas[i].PRODUCTS_CHAR,
                                PRODUCTS_REMARK: datas[i].PRODUCTS_REMARK,
                                APPLY_CONDISTION: datas[i].APPLY_CONDISTION,
                                MAKINGS: datas[i].MAKINGS,
                                APPLICABLE_REGION: datas[i].APPLICABLE_REGION,
                                LOGO_NAME: datas[i].LOGO_NAME,
                                LOGO_ADDR: datas[i].LOGO_ADDR
                            })
                        }
                    } else {
                        this.pagination.total = 0
                        this.pList = []
                    }
                } else {
                    this.pagination.total = 0
                    this.pList = []
                    this.$message.error('服务器异常，获取产品列表异常！')
                }
            })
        },
        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            this.getProductList()
        },
        onPickDate (date, dateString) {
            this.formGeren.chanpinyouxiaoqi = dateString
        },
        onPickTime (date, dateString) {
            this.formGeren.fabushijian = dateString
        },
        isValid () {
            if (this.formGeren.fuwuxiang === undefined || this.formGeren.fuwuxiang === '' || this.formGeren.fuwuxiang === null) {
                this.$message.info('请选择服务项！')
                return false
            } else if (this.formGeren.chanpinmingcheng === '') {
                this.$message.info('请输入产品名称！')
                return false
            } else if (this.formGeren.chanpinfenlei === undefined || this.formGeren.chanpinfenlei === '' || this.formGeren.chanpinfenlei === null) {
                this.$message.info('请选择产品分类！')
                return false
            } else if (this.formGeren.danbaofangshi === undefined || this.formGeren.danbaofangshi === '' || this.formGeren.danbaofangshi === null) {
                this.$message.info('请选择担保方式！')
                return false
            } else if (this.formGeren.qixianfanwei === undefined || this.formGeren.qixianfanwei === '' || this.formGeren.qixianfanwei === null) {
                this.$message.info('请选择期限范围！')
                return false
            } else if (this.formGeren.lilvqujian1 === '') {
                this.$message.info('请输入利率区间，以%结尾！')
                return false
            } else if (this.formGeren.edufanwei === undefined || this.formGeren.edufanwei === '' || this.formGeren.edufanwei === null) {
                this.$message.info('请选择额度范围！')
                return false
            } else if (this.formGeren.fabushijian === '') {
                this.$message.info('请选择发布时间！')
                return false
            } else if (this.formGeren.chanpinyouxiaoqi.length == 0) {
                this.$message.info('请选择产品有效期！')
                return false
            } else if (this.formGeren.shiyongquyu === '') {
                this.$message.info('请输入适用区域！')
                return false
            } else if (this.formGeren.chanpintedian === '') {
                this.$message.info('请输入产品特点！')
                return false
            } else if (this.formGeren.chanpinjianjie === '') {
                this.$message.info('请输入产品简介！')
                return false
            } else if (this.formGeren.shenqingtiaojian === '') {
                this.$message.info('请输入申请条件！')
                return false
            } else if (this.formGeren.tijiaocailiao === '') {
                this.$message.info('请输入提交材料！')
                return false
            } else if (this.formGeren.fenzhijigou === '') {
                this.$message.info('请选择分支机构！')
                return false
            } else {
                return true
            }
        },
        // 提交发布产品
        onSubmit () {
            let isValid = this.isValid()
            if (isValid) {
                if (this.isEdit) {
                    this.editProducts()
                } else {
                    this.addProducts()
                }
            }
        },
        addProducts () {
            let str = '', str1 = ''
            for (let i in this.bankLists) {
                if (this.bankLists[i].selected) {
                    str += this.bankLists[i].NAME + ','
                    str1 += this.bankLists[i].DEPARTMENT_ID + ','
                }
            }
            str = str.substring(0, str.length - 1)
            str1 = str1.substring(0, str1.length - 1)
            var params = new FormData()
            params.append('SERVICE_COLUMN',this.formGeren.fuwuxiang)
            params.append('BANK_ID',this.orgId)
            params.append('PRODUCT_NAME',this.formGeren.chanpinmingcheng)
            params.append('PRODUCT_TYPE',this.formGeren.chanpinfenlei)
            params.append('MONEY_START',this.formGeren.edufanwei)
            params.append('BETWEEN_MONTHS_S',this.formGeren.qixianfanwei)
            params.append('GUARANTEE_METHOD',this.formGeren.danbaofangshi)
            params.append('DATE_START',this.formGeren.chanpinyouxiaoqi[0])
            params.append('DATE_END',this.formGeren.chanpinyouxiaoqi[1])
            params.append('INTEREST_RATE_START',this.formGeren.lilvqujian1)
            params.append('RELEAS_TIME',this.formGeren.fabushijian)
            params.append('APPLICABLE_AGENT',this.formGeren.shiyongkehu)
            params.append('PRODUCTS_CHAR',this.formGeren.chanpintedian)
            params.append('PRODUCTS_REMARK',this.formGeren.chanpinjianjie)
            params.append('APPLY_CONDISTION',this.formGeren.shenqingtiaojian)
            params.append('MAKINGS',this.formGeren.shenqingtiaojian)
            params.append('APPLICABLE_REGION',this.formGeren.shiyongquyu)
            params.append('LOGO_NAME',this.fileName)
            params.append('STATUS',0)
            params.append('HANDINST_BANKNAME',str)
            params.append('HANDINST_BANKID',str1)
            params.append('tm',new Date().getTime())
            params.append('BANK_NAME',this.orgName,)
            params.append('FFILE',this.fileList)
            console.log(params)
            axios.post(this.$baseUrl+'/financialproducts/add',params).then(res=>{
                if (res.result === 'success') {
                    this.$message.success('发布产品成功！')
                    this.init()
                } else {
                    this.$message.info('系统异常！')
                }
            })
        },
        editProducts () {
            let str = '', str1 = ''
            console.log(this.bankLists)
            for (let i in this.bankLists) {
                if (this.bankLists[i].selected) {
                    console.log(this.bankLists[i].selected)
                    str += this.bankLists[i].NAME + ','
                    str1 += this.bankLists[i].DEPARTMENT_ID + ','
                }
            }
            // if (str == ''){
            //     str  = this.formGeren.HANDINST_BANKNAME
            //     str1 = this.formGeren.HANDINST_BANKID
            // }else{
                str = str.substring(0, str.length - 1)
                str1 = str1.substring(0, str1.length - 1)
            // }
            console.log(str,str1,'11212121')
             var params = new FormData()
             params.append('FINANCIALPRODUCTS_ID',this.proId)
            params.append('SERVICE_COLUMN',this.formGeren.fuwuxiang)
            params.append('BANK_ID',this.orgId)
            params.append('PRODUCT_NAME',this.formGeren.chanpinmingcheng)
            params.append('PRODUCT_TYPE',this.formGeren.chanpinfenlei)
            params.append('MONEY_START',this.formGeren.edufanwei)
            params.append('BETWEEN_MONTHS_S',this.formGeren.qixianfanwei)
            params.append('GUARANTEE_METHOD',this.formGeren.danbaofangshi)
            params.append('DATE_START',this.formGeren.chanpinyouxiaoqi[0])
            params.append('DATE_END',this.formGeren.chanpinyouxiaoqi[1])
            params.append('INTEREST_RATE_START',this.formGeren.lilvqujian1)
            params.append('RELEAS_TIME',this.formGeren.fabushijian)
            params.append('APPLICABLE_AGENT',this.formGeren.shiyongkehu)
            params.append('PRODUCTS_CHAR',this.formGeren.chanpintedian)
            params.append('PRODUCTS_REMARK',this.formGeren.chanpinjianjie)
            params.append('APPLY_CONDISTION',this.formGeren.shenqingtiaojian)
            params.append('MAKINGS',this.formGeren.tijiaocailiao)
            params.append('APPLICABLE_REGION',this.formGeren.shiyongquyu)
            params.append('LOGO_NAME',this.fileName)
            params.append('LOGO_ADDR',this.formGeren.LOGO_ADDR)
            params.append('STATUS',0)
            params.append('HANDINST_BANKNAME',str)
            params.append('HANDINST_BANKID',str1)
            params.append('tm',new Date().getTime())
            params.append('BANK_NAME',this.orgName,)
            params.append('FFILE',this.fileList)
            console.log(params)
            axios.post(this.$baseUrl+'/financialproducts/edit',params).then(res=>{
                if (res.result === 'success') {
                    this.$message.success('修改产品成功！')
                    this.init()
                } else {
                    this.$message.info('系统异常！')
                }
            })
            // this.$store.dispatch('editProducts', {
            //     FINANCIALPRODUCTS_ID: this.proId,
            //     SERVICE_COLUMN: this.formGeren.fuwuxiang,
            //     BANK_ID: this.orgId,
            //     PRODUCT_NAME: this.formGeren.chanpinmingcheng,
            //     PRODUCT_TYPE: this.formGeren.chanpinfenlei,
            //     MONEY_START: this.formGeren.edufanwei,
            //     BETWEEN_MONTHS_S: this.formGeren.qixianfanwei,
            //     GUARANTEE_METHOD: this.formGeren.danbaofangshi,
            //     DATE_START: this.formGeren.chanpinyouxiaoqi[0],
            //     DATE_END: this.formGeren.chanpinyouxiaoqi[1],
            //     INTEREST_RATE_START: this.formGeren.lilvqujian1,
            //     RELEAS_TIME: this.formGeren.fabushijian,
            //     APPLICABLE_AGENT: this.formGeren.shiyongkehu,
            //     PRODUCTS_CHAR: this.formGeren.chanpintedian,
            //     PRODUCTS_REMARK: this.formGeren.chanpinjianjie,
            //     APPLY_CONDISTION: this.formGeren.shenqingtiaojian,
            //     MAKINGS: this.formGeren.shenqingtiaojian,
            //     APPLICABLE_REGION: this.formGeren.shiyongquyu,
            //     LOGO_NAME: this.fileName,
            //     LOGO_ADDR: this.fileAddr,
            //     STATUS: 0,
            //     HANDINST_BANKNAME: str,
            //     HANDINST_BANKID: str1,
            //     tm: new Date().getTime(),
            //     BANK_NAME: this.orgName,
            //     FFILE: this.fileList
            // }).then(res => {
            //     if (res.result === 'success') {
            //         this.$message.success('修改产品成功！')
            //         this.init()
            //     } else {
            //         this.$message.info('系统异常！')
            //     }
            // })
        },
        onUpload (info) {
            // if (info.file.response) {
            //     if (info.file.response.result === 'success') {
            //         this.$message.success('上传成功！')
            //         this.fileAddr = info.file.response.FILE_ADDR
            //     } else {
            //         this.$message.error('上传失败！')
            //     }
            // }
        },
        beforeUpload (e) {
            console.log(e)
            this.fileList = []
            this.fileName = e.name
            this.fileList = e
        },
        // 获取下拉选项列表
        getSelectList () {
            // 获取服务项下拉选项列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: '5e3766b8ee284c929c72cbfeba325b3b', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.serviceList = res.list
                } else {
                    this.serviceList = []
                }
            })
            // 获取期限范围下拉选项列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: 'e7486ddcb4c143149deeecbe3ff9a58b', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.dateList = res.list
                } else {
                    this.dateList = []
                }
            })
            // 获取担保方式下拉选项列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: '6c14cf3422324b6aaf7a8637394c9b89', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.methodList = res.list
                } else {
                    this.methodList = []
                }
            })
            // 获取额度范围下拉选项列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: 'c81a6c18aef04a58892753d289640b9f', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.quotaList = res.list
                } else {
                    this.quotaList = []
                }
            })
        },
        // 初始化数据
        init () {
            this.isEdit = false
            this.formGeren.fenzhijigou = ''
            this.formGeren.fuwuxiang = undefined
            this.formGeren.chanpinmingcheng = ''
            this.formGeren.lilvqujian1 = ''
            this.formGeren.edufanwei = undefined
            this.formGeren.chanpintedian = ''
            this.formGeren.chanpinjianjie = ''
            this.formGeren.danbaofangshi = undefined
            this.formGeren.qixianfanwei = undefined
            this.formGeren.shenqingtiaojian = ''
            this.formGeren.tijiaocailiao = ''
            this.formGeren.chanpinfenlei = undefined
            this.formGeren.chanpinyouxiaoqi = []
            this.formGeren.shiyongquyu = ''
            this.formGeren.shiyongkehu = ''
            this.formGeren.fabushijian = ''
            this.proId = ''
            this.fileAddr = ''
            this.fileName = ''
            this.fileList = []
            this.searchBankVal = ''
            this.bankLists.map((item,index)=>{
                item.selected = false
            })
        },
        // 获取银行列表
        getBankList () {
            this.$store.dispatch('getDepBankList', { showCount: -1, currentPage: 1, BANKNAME: this.searchBankVal }).then(res => {
                if (res.result === 'success') {
                    if (this.isEdit) {
                        let arr = []
                        if (this.formGeren.fenzhijigou !== '') {
                            arr = this.formGeren.fenzhijigou.split(',')
                        }
                        for (let i in res.varList) {
                            if (arr.length != 0) {
                                for (let k in arr) {
                                    if (arr[k] == res.varList[i].NAME) {
                                        res.varList[i].selected = true
                                    }
                                }
                            } else {
                                res.varList[i].selected = false
                            }
                        }
                    } else {
                        for (let i in res.varList) {
                            res.varList[i].selected = false
                        }
                    }
                    this.bankLists = res.varList
                } else {
                    this.bankLists = []
                }
            })
        }
    },
    created () {
        // 获取下拉选项列表
        this.getSelectList()
    }
})
</script>

<style lang="less" scoped>
.content-box ::v-deep(.ant-table-thead){
    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
}
.content-box ::v-deep(.ant-table-thead th){
    background-color: transparent;
    color: @color-ff;
}
.content-box{
    text-align: center;
    ::v-deep(.ant-form-item-control-wrapper){
        margin: 0 auto;
        text-align: center;
    }
    .ant-btn{
        padding: 0 15px;
        height: 40px;
        color: @color-ff;
        margin: 0 5px;
        background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
    }
}
</style>
<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .ec-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            .tab {
                padding: 2px 15px;
                color: #A7A7A7;
                cursor: pointer;
            }
            .tab-active{
                padding: 2px 15px;
                color: #ffffff;
                cursor: pointer;
                background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
            .con-item-download{
                width: 100%;
                text-align: center;
                margin-bottom: 20px;
                a{
                    font-size: @font-lg;
                    display: inline-block;
                    text-decoration: underline;
                    color: @color-ff;
                    padding: 10px 60px;
                    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */


                }
            }
            .con-item-vercode{
                .ant-form-item{
                    .ant-input{
                        width: 55%;
                    }
                    .ant-btn{
                        width: 40%;
                        margin-left: 5%;
                    }
                }
            }
            .con-item-upload{
                width: 49%;
                ::v-deep(.ant-upload) {
                    width: 100%;
                }
                ::v-deep(.ant-btn){
                    width: 100%;
                }
            }
            .con-item-uploadimg{
                width: 30%;
                ::v-deep(.ant-upload){
                    width: 240px;
                    height: 195px;
                }
                ::v-deep(.ant-upload-list-item){
                    width: 240px;
                    height: 195px;
                }
                ::v-deep(.ant-upload-list-picture-card-container){
                    height: 195px;
                }
            }
            .contents{
                margin: 0 auto;
                ::v-deep(.ant-form-item-control-wrapper){
                    margin: 0 auto;
                    text-align: center;
                }
                .ant-btn{
                    padding: 0 120px;
                    height: 40px;
                    color: @color-ff;
                    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
                }
            }
        }
    }
    .ec-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
}
</style>
